<template>
  <div class="page-body">
    <div class="page-top">
      <div class="page-top-body">
        <div class="col-auto">
          <div class="gt-xs" id="logo">
            <img src="../assets/imgaes/logo.png" alt="">
          </div>
        </div>
        <div id="user">
          <span @click="goGw()"> <i> <img src="../assets/imgaes/gw-icon.png" alt="" > </i>{{$t('home.gw')}}</span>
          <span>
            <i> <img src="../assets/imgaes/language.png" alt="语言"> </i> {{$t('home.SwitchLanguage')}}
            <q-menu :offset="[-20,5]">
              <q-list>
                <q-item clickable v-close-popup @click="tabLocale('zh')">
                  <q-item-section>简体中文</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="tabLocale('en')">
                  <q-item-section>English</q-item-section>
                </q-item>
              </q-list>
             </q-menu>
          </span>
          <span>
             <i> <img src="../assets/imgaes/login-icon.png" alt=""> </i>
            {{$t('home.user')}}
            <q-menu anchor="bottom end" self="top right">
              <div class="user_info">
                <div class="user_info_left">
                  <div class="user_info_title">{{$store.state.userInfo.username}}</div>
                  <div class="user_info_zx">
                    <p>{{$t('home.DaysToExpiration')}}：{{$store.state.userInfo.validity}}</p>
                    <p v-if="$store.state.userInfo.identity == 2" >
                      {{$t('home.teacher')}}：
                      <span v-if="$store.state.userInfo.bd_info.length > 0 ">{{$store.state.userInfo.bd_info[0].username}}</span> 
                      <span v-if="$store.state.userInfo.bd_info.length == 0 " @click="$store.state.teacherType = true">{{$t('home.FollowTeacher')}}</span>
                    </p>
                    <p v-if="$store.state.userInfo.identity == 1" style="margin-top: 12px">
                      <!-- {{$t('home.teacher')}}： -->
                      <span style="margin-right:19px" v-if="$store.state.userInfo.bd_info.length > 0 " @click="$store.state.studentListType = true">{{$t('home.StudentList')}}</span>
                      <span  @click="$store.state.studentType = true">{{$t('home.OK')}}</span>
                    </p>
                  </div>
                  <div class="user_info_bot" :style="$store.state.userInfo.identity == 1 ?'margin-top: 12px':''">
                    <span @click="$store.state.editPwdType = true">{{$t('home.ChangePassword')}}</span>
                    <span>{{$t('home.UserManual')}}</span>
                  </div>
                </div>
                <div class="user_info_right">
                  <div class="user_tx">
                    <el-upload
                            class="avatar-uploader"
                            action="http://www.qbmusic.net/index/ajax/upload"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            >
                            <img v-if="$store.state.userInfo.avatar" :src="$store.state.userInfo.avatar" class="avatar">
                          <div class="edit-avatar">
                            <i class="el-icon-edit"></i>
                            <p>{{$t('home.ModifyAvatar')}}</p>
                          </div>
                    </el-upload>
                  </div>
<!--                  <div class="user_name">{{$store.state.userInfo.username}}</div>-->
                    <q-btn class="out" :label="$t('home.LogOut')" @click="outLogin"></q-btn>
                </div>
              </div>
             </q-menu>
          </span>

          <span @click="$router.push({path:'/notice'})"> <i> <img src="../assets/imgaes/notice.png" alt=""> </i>{{$t('home.Notice')}}</span>
        </div>
      </div>
    </div>
    <breadcrumbs></breadcrumbs>
    <div id="page-content">
      <home v-if="$route.name == 'Home'" />
      <router-view v-else></router-view>
    </div>
    <div id="page-floot" v-if="$route.path != '/wrongQuestion'">
      Copyright © 2022 - 2028
    </div>
    <wrongQuestionPage v-else></wrongQuestionPage>
    <!--购买组题权限 弹窗-->
    <Buy v-if="$store.state.buy"></Buy>
    <!--修改密码 弹出框-->
    <editPwd v-if="$store.state.editPwdType" />
    <!--绑定老师 弹出框-->
    <teacher v-if="$store.state.teacherType" />
    <!-- 绑定学生 弹出框 -->
    <student v-if="$store.state.studentType"></student>
    <!-- 展示学生列表  -->
    <studentList v-if="$store.state.studentListType"></studentList>

  </div>
</template>

<script>

    import breadcrumbs from "@/components/breadcrumbs";
    import home from "@/views/Home/index"
    import Buy from "@/components/Buy";
    import editPwd from "@/components/editPwd";
    import Teacher from "@/components/Teacher";
    import student from "@/components/student";
    import studentList from "@/components/studentList";
    import wrongQuestionPage from "../components/wrongQuestionPage";
export default {
  name: "Home",
  components:{
    home,
    breadcrumbs,
    Buy,
    editPwd,
    Teacher,
    student,
    studentList,
    wrongQuestionPage
  },
  data() {
    return {
      showing: false,
      pwd:true,
      imageUrl:""
      // userInfo:[]
    }
  },
  created() {
    this.getUserInfo()
  },
  methods:{
    goGw(){
      window.open('//www.qbmusic.net/')
    },
    tabLocale(locale){
      this.$i18n.locale = locale
      this.$store.state.paperLanguage = locale
      localStorage.setItem('locale',locale)
      let lang = ''
      if (locale == 'zh'){
        lang = 'zh-cn'
      }else {
        lang = 'en'
      }
      this.$http.post('index/lang',{
        lang:lang
      }).then(res =>{
        console.log(res)
      }).catch(err =>{
        console.log(err)
      })
    },
    outLogin(){
      localStorage.removeItem('token')
      this.$router.push({path:'/login'})
    },
    // 获取用户信息
    getUserInfo(){
      this.$http.post('user/user_info').then(res =>{
        console.log('用户信息-------------',res)
        this.$store.state.userInfo = res.data
      })
    },
    handleAvatarSuccess(res){
      console.log(res)
      this.$store.state.userInfo.avatar = res.data.fullurl;
      this.sveAvatar(res.data.url)
    },
    // 保存用户图像接口
    sveAvatar(avatar){
      this.$http.post('user/save_userinfo',{
        avatar:avatar
      }).then(res =>{
        console.log(res)
        this.$q.notify({
          type: "positive",
          position: "top",
          timeout: "500",
          message: res.msg,
        });
      }).catch(err =>{
        this.$q.notify({
          type: "negative",
          position: "top",
          timeout: "500",
          message: err.msg,
        })
      })
    }
  }

}
</script>
<style scoped lang="less">
.page-body {
  max-width: 1200px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  background: none;
  .page-top{
    background: #fffaf6;
    padding-bottom: 12px;
  }
  .page-top-body {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    #logo {
      margin-left: 33px;
      display: flex;
    }

    #user {
      display: flex;
      /*align-items: center;*/
      justify-content: space-around;
      span {
        margin-left: 40px;
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 20px;
        cursor: pointer;
        padding:7px 0;
        img{
          display: block;
          margin-right: 11px;
        }
         @media (max-width: 768px) {
                font-size: 14px;
             }
      }
    }
  }
  .page-title{
    width: 100%;
    height: 59px;
    border-bottom: 2px solid #ED711C;
    display: flex;
    align-items: center;
    padding-left: 21px;
    border-top: 2px solid #ececec;
    background: #ffffff;
    font-size: 18px;
  }
}
.user_info {
  width: auto;
  height: 204px;
  position: relative;
  //margin-top: 15px;
  padding-left: 30px;
  padding-right: 33px;
  padding-top: 28px;
  padding-bottom: 26px;
  display: flex;
  justify-content: space-between;
  p{
    margin-bottom: 0;
    font-size: 16px;
    color: #333333;
  }
  span{
    font-size: 16px;
    color: #ED711C;
    border-bottom: 1px solid #ED711C;
    cursor: pointer;
  }
  .user_info_bot{
    margin-top: 25px;
    span{
      margin-right: 19px;
    }
  }
  .user_info_title{
    font-size: 24px;
    color: #333333;
    margin-bottom: 16px;
  }
  .user_tx{
    width: 83px;
    height: 83px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .user_info_right{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
    .user_tx img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .user_name{
      color: #333333;
      font-size: 20px;
      text-align: center;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .out{
      width: auto;
      height: 28px;
      background: #ED711C;
      color: #ffffff;
      font-size: 16px;
      /deep/.q-btn__wrapper{
        padding: 0 10px;
        min-height: auto;
      }
    }
  }

}

.user_info::after{
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid red;
  top: -15px;
  right: 0;
  position: absolute;
}
#page-content {
  position: absolute;
  width: 100%;
  top: 139px;
  bottom: 80px;
  overflow: auto;
  background: #ffffff;
  @media (max-width: 768px) {
    top: 128px;
  }
}
  #page-floot{
    position: absolute;
    height: 80px;
    width: 100%;
    bottom: 0;
    line-height: 40px;
    padding: 0 33px;
    display: flex;
    align-items: center;
    font-size: 18px;
    background: #ffffff;
  }
/deep/ .avatar-uploader .el-upload {
  width: 83px;
  height: 83px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.edit-avatar{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  i{
    font-size: 16px;
    color: #FFFFFF;
  }
  p{
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 5px;
  }
  transition: all 0.3s;
  opacity: 0;
}
.avatar-uploader:hover .edit-avatar{
  opacity: 1;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

@media screen and  (min-width:0px) and (max-width: 900px) {

  #user{
    width: 100%;
    margin: 0 auto;
  }
  #user>span{
    margin-left: 0px !important;
  }
  .q-menu{
    max-width: 340px !important;
  }
}
</style>