<template>
    <div>
        <q-dialog v-model="$store.state.editPwdType" content-class="pop">
            <div class="pop-box">
                <q-btn class="pop-close" @click="$store.state.editPwdType = false" style="color: #FF0000" icon="close" flat round dense  />
                <div class="pop-title">修改密码</div>
                <q-form ref="myForm"
                        @submit="onSubmit"
                        @reset="onReset"
                >
                    <q-input borderless bottom-slots   v-model="name"  lazy-rules :rules="[ verAccount ]">
                        <template v-slot:prepend>
                            <span class="input-lable">账号*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请输入账号</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots type="password"   v-model="oldPwd"  lazy-rules :rules="[ verOldPwd ]">
                        <template v-slot:prepend>
                            <span class="input-lable">原密码*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请输入原密码</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots type="password"   v-model="newPwd"  lazy-rules :rules="[ verOldPwd ]">
                        <template v-slot:prepend>
                            <span class="input-lable">新密码*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请输入新密码</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots type="password"   v-model="confirmPwd"  lazy-rules :rules="[ verConfirmPwd ]">
                        <template v-slot:prepend>
                            <span class="input-lable">确认密码*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请再次输入新密码</span>
                        </template>
                    </q-input>
                    <div class="pop-bottom">
                        <q-btn class="pop-btn" unelevated  flat label="确认修改" type="submit" />
                    </div>
                </q-form>
            </div>
        </q-dialog>
    </div>
</template>

<script>
    import { account,verOldPwd,verNewPwd,verConfirmPwd } from "../assets/Validation";

    export default {
        name: "editPwd",
        data(){
            return{
                pwd:true,
                name: '',
                oldPwd:'',
                age: null,
                accept: false,
                newPwd:'',
                confirmPwd:''
            }
        },
        created() {

        },
        methods:{
            onSubmit () {
                console.log('1111')
                this.$refs.myForm.validate().then(success => {
                    if (success) {
                        this.$http.post('user/edit_password',{
                            old_password:this.oldPwd,
                            new_password:this.newPwd,
                            new_repassword:this.confirmPwd,
                        }).then(res =>{
                            console.log(res)
                            this.$store.state.editPwdType = false
                            this.$q.notify({
                                type: 'positive',
                                position:'top',
                                timeout:'500',
                                message: `修改成功,请重新登录`
                            })
                            localStorage.removeItem('token')
                            this.$router.push({path:'/login'})
                        }).catch(err =>{
                            this.$q.notify({
                                type: 'negative',
                                position:'top',
                                timeout:'500',
                                message: err.msg
                            })
                        })

                        // 是的，模型是正确的
                    }
                    else {
                        // 哦，不，用户至少
                        // 填写了一个无效值
                        console.log('11111')
                    }
                })
            },
            verAccount (val) {
                return new Promise((resolve) => {
                    if (!account(val)){
                        resolve(!!val || '请输入账号')
                    }else {
                        resolve()
                    }
                })
            },
            verOldPwd(val){
                return new Promise((resolve) => {
                    if (!verOldPwd(val)){
                        resolve(!!val || '请输入原密码')
                    }else {
                        resolve()
                    }
                })
            },
            verNewPwd(val){
                return new Promise((resolve) => {
                    if (!verNewPwd(val)){
                        resolve(!!val || '请输入新密码')
                    }else {
                        resolve()
                    }
                })
            },
            verConfirmPwd(val){
                return new Promise((resolve) => {
                    console.log(verConfirmPwd(val,this.newPwd))
                    if (verConfirmPwd(val,this.newPwd) === 0){
                        resolve('请再次输入新密码')
                    }else if (verConfirmPwd(val,this.newPwd) === 1) {
                        console.log('两次密码不一致')
                        resolve('两次密码不一致')
                    }else {
                        resolve()
                    }
                })
            },
            onReset () {
                this.name = null
                this.age = null
                this.accept = false
            }
        },
    }
</script>

<style scoped>

</style>