<template>
  <div class="page-title">
    <q-breadcrumbs v-if="$i18n.locale == 'zh'">
      <q-breadcrumbs-el v-for="(item,index) in breadList" :key="index" :to="item.path" :label="item.meta.title" />
    </q-breadcrumbs>
     <q-breadcrumbs v-if="$i18n.locale == 'en'">
      <q-breadcrumbs-el v-for="(item,index) in breadList" :key="index" :to="item.path" :label="item.meta.titleEn" />
    </q-breadcrumbs>
      <div class="zujian-fh" v-if="$route.path == '/teacherManualModular' || $route.path == '/questionBank' ">
          <span @click="$router.go(-1)"> <img src="../assets/imgaes/fanhui.png" alt=""> {{$t('home.Return')}}</span>
<!--          <span @click="isQueDing"> <img src="../assets/imgaes/queding.png" alt="">确定</span>-->
      </div>

  </div>
</template>

<script>
export default {
  name: "breadcrumbs",
  data(){
    return{
      breadList: [] // 路由集合
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  created() {
    this.getBreadcrumb();

  },
  methods:{
    isHome(route) {
      // return route.name === "Home";
      return route.path === '/'
    },
    getBreadcrumb() {
      let matched = this.$route.matched;
      // matched.splice(0,1)
      console.log(matched)
      //如果不是首页
      if (!this.isHome(matched[0])) {
        matched[0].path = '/'
        // matched = [{ path: "/Home", meta: { title: "首页" } }].concat(matched);
      }
      // matched.forEach((item,index)=>{
      //   console.log(!item.meta.title)
      //   if (!item.meta.title){
      //     console.log('1111')
      //     matched.splice(index,1)
      //   }
      //   console.log(matched)
      // })
      this.breadList = matched;
      console.log('面包屑列表',this.breadList)
    },
    isQueDing(){
        this.$store.state.topQueDing = this.$route.path
    }
  }
}
</script>

<style scoped lang="less">
.zujian-fh{
    position: absolute;
    right: 0;
    padding-right: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span{
        font-size: 16px;
        color: #ED711C;
        margin-left: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            margin-right: 10px;
        }
    }
}
/deep/.text-primary{
    color: #ED711C !important;
}
</style>