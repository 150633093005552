<template>
    <div>
        <q-dialog v-model="$store.state.studentType" content-class="pop">
            <div class="pop-box">
                <q-btn class="pop-close" @click="$store.state.studentType = false" style="color: #FF0000" icon="close" flat round dense  />
                <div class="pop-title">{{$t('home.OK')}}</div>
                <q-form ref="myForm"
                        @submit="onSubmit"
                        @reset="onReset"
                >
                    <q-input borderless bottom-slots   v-model="name"  lazy-rules :rules="[ verTeacher ]">
                        <template v-slot:prepend>
                            <span class="input-lable">{{$t('home.StudentsAccount')}}*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">{{$t('home.EnterStudentsAccount')}}</span>
                        </template>
                    </q-input>
                    <div class="pop-bottom">
                        <q-btn class="pop-btn" unelevated  flat :label="$t('home.ConfirmBinding')" type="submit" />
                    </div>
                </q-form>
            </div>
        </q-dialog>
    </div>
</template>

<script>
    import { verTeacher} from "../assets/Validation";

    export default {
        name: "student",
        data(){
            return{
                pwd:true,
                name: '',
                accept: false,
            }
        },
        created() {

        },
        methods:{
            getUserInfo(){
                this.$http.post('user/user_info').then(res =>{
                    console.log('用户信息-------------',res)
                    this.$store.state.userInfo = res.data
                })
            },
            onSubmit () {
                console.log('1111')
                this.$refs.myForm.validate().then(success => {
                    if (success) {
                        this.$http.post('user/binding',{
                            username:this.name
                        }).then(res=>{
                            this.$store.state.teacherType = false
                            this.$q.notify({
                                type: 'positive',
                                position:'top',
                                timeout:'500',
                                message: res.msg
                            })
                            this.getUserInfo()
                        }).catch(err =>{
                            this.$q.notify({
                                type: 'negative',
                                position:'top',
                                timeout:'500',
                                message: err.msg
                            })
                        })

                        // 是的，模型是正确的
                    }
                    else {
                        // 哦，不，用户至少
                        // 填写了一个无效值
                        console.log('11111')
                    }
                })
            },
            verTeacher(val){
                return new Promise((resolve) => {
                    if (!verTeacher(val)){
                        resolve(!!val || '请输入学生账号')
                    }else {
                        resolve()
                    }
                })
            },
            onReset () {
                this.name = null
                this.age = null
                this.accept = false
            }
        },
    }
</script>

<style scoped>

</style>