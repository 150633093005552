

export function phone (value){
    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    console.log(value)
    if (!reg.test(value)){
        return false
    }else {
        return true
    }
}
export function account(val) {
    // console.log(val)
    if (val === ''){
        return false
    }else {
        return true
    }
}
export function verOldPwd(val) {
    if (val === ''){
        return false
    }else {
        return true
    }
}
export function verNewPwd(val) {
    if (val === ''){
        return false
    }else {
        return true
    }
}
export function verConfirmPwd(val,newPwd) {
    console.log('撒打算',val,newPwd)
    if (val === ''){
        return 0
    }else if (val != newPwd) {
        return 1
    }else {
        return true
    }
}
export function verTeacher(val) {
    if (val === ''){
        return false
    }else {
        return true
    }
}
export function verPaperName(val) {
    if (val === ''){
        return false
    }else {
        return true
    }
}
export function verExamName(val) {
    // let reg = /^(?:(?!0000)[0-9]{4}(?:(?:0[1-9]|1[0-2])(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])(?:29|30)|(?:0[13578]|1[02])31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)0229)_[0-9a-zA-Z]*$/
    // console.log(reg.test(val))
    if (val === ''){
        return true
    }else {
        return false
    }
}
export function verDuration(val) {
    let reg = /^[0-9]*[1-9][0-9]*$/
    if (val === ''){
        return 0
    } else if (!reg.test(val)){
        return 1
    }else {
        return true
    }
}
// 邮箱验证
export function email(val) {
    let reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    if(!reg.test(val)){
        return false
    }else{
        return true
    }
}