<template>
    <div class="home row">
        <div class="home-body col-xs-12 col-sm-6 col-md-4" v-for="(item,index) in homeList" :key="index">
            <q-card class="home-option" flat v-if="$store.state.paperLanguage == 'zh'">
                <q-card-section>
                    <div class="card-title">{{ item.name }}</div>
                    <div class="card-des">
                        <p>{{ item.describe }}</p>
                        <img :src="$store.state.imgHttp+item.icon_image" alt="">
                    </div>
                </q-card-section>
                <q-separator style="background: #d3d3d3"/>
                <q-card-actions>
                    <div class="card-btn">
                        <q-btn class="card-btn-star" @click="clickStart(item.id)" flat>{{$t('home.start')}}</q-btn>
                    </div>
                </q-card-actions>
            </q-card>
            <q-card class="home-option" flat v-if="$store.state.paperLanguage == 'en'">
                <q-card-section>
                    <div class="card-title">{{ item.name_en }}</div>
                    <div class="card-des">
                        <p>{{ item.describe_en }}</p>
                        <img :src="$store.state.imgHttp+item.icon_image" alt="">
                    </div>
                </q-card-section>
                <q-separator style="background: #d3d3d3"/>
                <q-card-actions>
                    <div class="card-btn">
                        <q-btn class="card-btn-star" @click="clickStart(item.id)" flat>{{$t('home.start')}}</q-btn>
                    </div>
                </q-card-actions>
            </q-card>
        </div>
        <Buy v-if="$store.state.pay"></Buy>
    </div>
</template>

<script>
    import Buy from "../../components/Buy";
    export default {
        name: "index",
        components:{
            Buy,
        },
        data(){
            return{
                homeList: []
            }
        },
        created() {
            this.getHomeList()
            this.ifBuyExam()
            // this.$http.post('index/pv_uv',{type:1})
        },
        
        methods: {
            // 获取首页列表 index/index
            getHomeList(){
                this.$http.post('index/index').then(res=>{
                    console.log(res)
                    this.homeList = res.data
                })
            },
            onItemClick() {

            },
            ifBuyExam(){



            },
            clickStart(id) {
                switch (id) {
                    case 1:
                        this.$http.post('user_product/check_product_user',).then(res =>{
                            console.log(res)
                            this.$router.push({path:'/choose-exam'})
                        }).catch(err =>{
                            console.log(err)
                            this.$store.state.butType = 1
                            this.$store.state.pay = true
                        })
                        break;
                    case 2:
                        this.$router.push({path:'/wrongBook'})
                        break;
                    case 3:
                        this.$router.push({path:'/practiceNotes'})
                        break;
                    case 4:
                        this.$router.push({path:'/groupQuestionsList'})
                        break;
                    case 5:
                        this.$http.post('user_product/check_product_user',).then(res =>{
                            console.log(res)
                            this.$router.push({path:'/teacherGroupQuestions'})
                        }).catch(err =>{
                            console.log(err)
                            this.$store.state.butType = 2
                            this.$store.state.pay = true
                        })
                        break;
                }
                console.log(id)

            }
        }
    }
</script>

<style scoped lang="less">
    .home {
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 15px;
        margin-top: 20px;
    }
    .home-body {
        margin-bottom: 20px;
    }
    .home-option {
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        margin-right: 15px;
    }
    .card-title {
        font-size: 24px;
        color: #333333;
        margin-bottom: 14px;
    }
    .card-des {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 44px;
        p {
            height: 50px;
            max-width: 228px;
            font-size: 12px;
            color: #999999;
            margin-bottom: 5px;
            /*margin-top: 27px;*/
        }
    }
    .card-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .card-btn-star {
            width: 70px;
            height: 37px;
            border: 1px solid #ED711C;
            border-radius: 19px;
            font-size: 13px;
            color: #ED711C;
        }
    }
</style>