<template>
    <div>
        <q-dialog v-model="$store.state.studentListType" content-class="pop">
            <div class="pop-box">
                <q-btn class="pop-close" @click="$store.state.studentListType = false" style="color: #FF0000" icon="close" flat round dense  />
                <div class="pop-title">{{$t('home.ManagingStudents')}}</div>
                <div class="pop-stu-list">
                    <ul>
                        <li v-for="(item,index) in $store.state.userInfo.bd_info" :key="index">
                            <div class="stu-list-left">
                                <div class="stu-tx">
                                    <img :src="item.avatar" alt="">
                                </div>
                                <span>
                                    {{item.username}}
                                </span>
                            </div>
                            <div class="stu-list-right">
                                <q-btn v-if="item.status == 1" class="pop-jinyong" @click="jinyong(item.id)" unelevated  flat :label="$t('home.jinyong')"  />
                                <q-btn v-if="item.status == 0" class="pop-jinyong" style="background: #66bb6a" @click="EnableStu(item.id)" unelevated  flat :label="$t('home.Enable')"  />
                            </div>

                        </li>
                    </ul>
                </div>
                <div class="pop-bottom">
                    <q-btn class="pop-btn" unelevated  flat :label="$t('home.AddStudents')" @click="$store.state.studentType = true" />
                </div>
            </div>
        </q-dialog>
    </div>
</template>

<script>
    import { verTeacher} from "../assets/Validation";

    export default {
        name: "studentList",
        data(){
            return{
                pwd:true,
                name: '',
                accept: false,
            }
        },
        created() {

        },
        methods:{
            // 禁用
            jinyong(student_id){
                this.$http.post('user/disable_student',{
                    student_id:student_id
                }).then(res =>{
                    console.log(res)
                    this.$q.notify({
                        type: 'positive',
                        position:'top',
                        timeout:'500',
                        message: res.msg
                    })
                    this.getUserInfo()
                }).catch(err =>{
                    console.log(err)
                    this.$q.notify({
                        type: 'negative',
                        position:'top',
                        timeout:'500',
                        message: err.msg
                    })
                })
            },
            // 启用学生信息 user/undisable_student
            EnableStu(id){
                this.$http.post('user/undisable_student',{
                    student_id:id
                }).then(res =>{
                    console.log(res)
                    this.$q.notify({
                        type: 'positive',
                        position:'top',
                        timeout:'500',
                        message: res.msg
                    })
                    this.getUserInfo()
                }).catch(err =>{
                    console.log(err)
                    this.$q.notify({
                        type: 'negative',
                        position:'top',
                        timeout:'500',
                        message: err.msg
                    })
                })
            },
            getUserInfo(){
                this.$http.post('user/user_info').then(res =>{
                    console.log('用户信息-------------',res)
                    this.$store.state.userInfo = res.data
                })
            },
            onSubmit () {
                console.log('1111')
                this.$refs.myForm.validate().then(success => {
                    if (success) {
                        this.$http.post('user/binding',{
                            username:this.name
                        }).then(res=>{
                            this.$store.state.teacherType = false
                            this.$q.notify({
                                type: 'positive',
                                position:'top',
                                timeout:'500',
                                message: res.msg
                            })
                        }).catch(err =>{
                            this.$q.notify({
                                type: 'negative',
                                position:'top',
                                timeout:'500',
                                message: err.msg
                            })
                        })

                        // 是的，模型是正确的
                    }
                    else {
                        // 哦，不，用户至少
                        // 填写了一个无效值
                        console.log('11111')
                    }
                })
            },
            verTeacher(val){
                return new Promise((resolve) => {
                    if (!verTeacher(val)){
                        resolve(!!val || '请输入老师账号')
                    }else {
                        resolve()
                    }
                })
            },
            onReset () {
                this.name = null
                this.age = null
                this.accept = false
            }
        },
    }
</script>

<style scoped lang="less">
.pop-stu-list{
    ul{
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 7px;
            margin-bottom: 17px;
            .stu-list-left{
                display: flex;
                align-items: center;
                .stu-tx{
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                span{
                    font-size: 20px;
                    color: #333333;
                    margin-left: 11px;
                }
            }
            .pop-jinyong{
                min-width: 55px;
                padding: 0 5px;
                height: 30px;
                background: #ED711C;
                border-radius: 7px;
                color: #ffffff;
                /deep/ .q-btn__wrapper{
                    padding: 0;
                    min-height: auto;
                    display: flex;
                    align-items: center;
                    line-height: normal;
                }
            }
        }
    }
}
</style>