<template>
    <div>
        <q-dialog v-model="$store.state.pay" style="" persistent transition-show="scale" transition-hide="scale">
            <q-card class="text-white" style="width: 668px;height: 55%;border-radius: 29px;overflow: hidden;">
                <q-card-section class="pay-top">
                    <div class="text-h6">{{$t('home.Notices')}}</div>
                </q-card-section>

                <q-card-section class="q-pt-none pay-body">
                    抱歉！您尚未开通试卷权限，请点击【购买】，解锁完整题库。
                </q-card-section>

                <q-card-actions class="text-teal pay-btn" >
                    <q-btn flat class="jieshu-quxiao"  :label="$t('home.Cancel')" v-close-popup/>
                    <q-btn flat class="jieshu-end" :label="$t('home.BuyNow')" @click="buyNow()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
    export default {
        name: "Buy",
        data(){
            return{
                ExamLeve:[]
            }
        },
       created() {
            console.log(this.$route.path)
           this.getExamLeve()
       },
        methods:{
            // 获取试卷列表
            getExamLeve(){
                this.$http.post('product_type/get_product_type').then(res =>{
                    console.log(res)
                    this.ExamLeve = res.data
                })
            },
           buyNow(){
               console.log(this.$store.state.butType,'-------------')
               if (this.$store.state.butType == 1){
                   window.open('//www.qbmusic.net/addons/shopro/goods/shopro_detail/id/53.html','_blank')
               }else if (this.$store.state.butType == 2){
                   window.open('//www.qbmusic.net/addons/shopro/goods/shopro_detail/id/52.html','_blank')
               }


               this.$store.state.pay = false
               // this.$http.post('index/user_label')
               // this.$http.post('user_product/save_user_product',{
               //     product_type_id:4
               // }).then(res =>{
               //     console.log(res)
               //     this.$q.notify({
               //         type: "positive",
               //         position: "top",
               //         timeout: "500",
               //         message: res.msg,
               //     });
               //     if (this.$route.path == '/groupQuestionsList'){
               //         sessionStorage.setItem('buyAi','1')
               //     }else {
               //         sessionStorage.setItem('teacherBuy','1')
               //     }
               //     this.$store.state.pay = false
               // })
           },
       }
    }
</script>

<style scoped lang="less">
    .pay-top{
        width: 100%;
        height: 38%;
        background: url("../assets/imgaes/pay-top.png") no-repeat center;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .text-h6{
            font-size: 35px;
            font-weight: bold;
            margin-top: 25px;
        }
    }
    .pay-body{
        width: 100%;
        font-size: 24px;
        color: #000033;
        line-height: 47px;
        padding-top: 5%;
        /*padding-bottom: 109px;*/
        padding-left: 48px;
    }
    .pay-btn{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #ED711C;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        .jieshu-quxiao{
            padding: 0 50px;
            height: 50px;
            text-align: center;
            border-radius: 20px;
            color: #ffffff;
            background: #FFA666;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: normal;
             @media (max-width: 768px) {
                padding: 0 20px;
             }
        }
        .jieshu-end{
            padding: 0 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: normal;
            text-align: center;
            border-radius: 20px;
            color: #ffffff;
            border: 1px solid #ffffff;
            font-size: 24px;
            margin-left: 64px;
            @media (max-width: 768px) {
                padding: 0 20px;
                margin-left: 20px;
             }
        }
    }
   
</style>