<template>
    <div id="page-floot">
    <q-card class="my-card" flat id="q-nav" style="width: 47%;">
        <div class="select-container" style="padding: 0;">
            <ul class="pagination" ref="pagination">
                <li >
                    <a @click="switchPage(true)" aria-label="Previous">
                            <span aria-hidden="true">
                                 <img src="../assets/imgaes/left.png" alt="">
                            </span>
                    </a>
                </li>
                <li ref="pages"
                    v-for="(item,index) in pageList" :key="index"
                    @click="currentPage(index)"
                    :class="{'active':active === index }"
                >
                    <span>{{index+1}}</span>
                </li>
                <li >
                    <a @click="switchPage(false)"   aria-label="Next">
                            <span aria-hidden="true">
                                <img src="../assets/imgaes/right.png" alt="">
                            </span>
                    </a>
                </li>
            </ul>
        </div>

    </q-card>
    </div>
</template>

<script>
    export default {
        name: "pagination",
        data(){
            return{
                activeCurrentId:0,
                current: 1,
                max:0,
                pageList:[],
                active:0
            }
        },
        created() {
            this.getWrongList(this.$route.query.type,this.$route.query.id)
        },
        mounted: function() {
            window.addEventListener("resize", this.showPage)
        },
        updated: function() {
            console.log('1111')
            this.showPage()
        },
        methods:{
            // 获取错题详情列表
            getWrongList(type,id){
                this.$http.post('wrong_question/wrong_list',{
                    type:type,
                    id:id
                }).then(res =>{
                    console.log('错题详情',res)
                    this.pageList = res.data.question
                    this.getWrongDes(this.pageList[0].question_id)
                    this.$nextTick(()=>{
                        this.showPage()
                    })

                })
            },
            // 获取错题详情
            getWrongDes(question_id){
                this.$http.post('wrong_question/wrong_detail',{
                    question_id:question_id
                }).then(res =>{
                    console.log('错题详情',res)
                    this.$store.state.qusData = res.data
                    this.$store.state.qusType = res.data.question_type_id
                })
            },
            currentPage(e){
                console.log('分页点击',e)
                this.active = e
                this.getWrongDes(this.pageList[e].question_id)
            },
            clearHuiDa(){
                this.$parent.clearHuiDa()
            },
            switchPage(t){
                for (
                    var e,
                        i = this.$refs.pagination.offsetWidth,
                        s = this.$refs.pages.length,  // 总长度
                        n = parseInt(i / 41) - 3, //
                        a = 0,
                        o = 0;
                    o < s;
                    o++
                ){
                    console.log('总长度',s)
                    console.log('循环',o)
                    console.log('个数',n)
                    if ("none" != this.$refs.pages[o].style.display) {
                        a = o;
                        break
                    }

                }
                console.log(e,i,s,n,a,o,t)
                e = t ? a >= n ? a - n : 0 : a + 2 * n <= s ? a + n : s - n;
                console.log('每页展示个数', e)
                console.log()
                for (var l = 0; l < s; l++){
                    if (l >= e && l <= e - 1 + n){
                        console.log('L',l)
                        this.$refs.pages[l].style.display = ""
                    }else {
                        this.$refs.pages[l].style.display = "none"
                    }
                }
            },
            showPage() {
                var t = this.$refs.pagination.offsetWidth
                    , e = this.$refs.pages.length
                    , i = parseInt(t / 41) - 3
                    , s = parseInt(i / 2)
                    , n = this.active
                    , a = n >= s ? s : n
                    , o = e - n >= s ? s : e - n;
                    a < s && (o += s - a),
                    o < s && (a += s - o);
                for (var l = n - a, r = n + o, c = 0; c < e; c++){
                    this.$refs.pages[c].style.display = "none",
                    c >= l && c <= r && (this.$refs.pages[c].style.display = "")
                    if (c == 10){
                        this.$refs.pages[c].style.display = "none"
                    }
                }

            },
            updateQuestion(item,index){
                this.active = index
                // this.$parent.updateQuestion(item,this.$route.query.practice_id)
            }
        }
    }
</script>

<style scoped lang="less">
    // 分页样式
    .select-container{
        padding: 10px 40px;
        display: flex;
        align-items: center;
        /*justify-content: space-between;*/
        .pagination{
            margin-top: 6px;
            flex: 1;
            .flag{
                position: absolute;
                top: -15px;
                left: 0;
                right: 0;
                margin: auto;
            }
            .err{
                position: absolute;
                top: -27px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        .pagination>li{
            display: inline-block;
        }
        .pagination li:hover>span {
            color: #fff
        }

        .pagination li:hover>span {
            background-image: url("../assets/imgaes/pagination-bg.png");
        }

        .pagination li:hover a {
            color: #fff
        }
        .pagination>li>a,.pagination>li>span{
            float: left;
            margin-left: -1px
        }
        .pagination>li>a,.pagination>li>span{
            padding: 6px 12px;
            /*background-color: #fff;*/
            border: 1px solid #999
        }
        .pagination li:hover a {
            background-color: inherit
        }

        .pagination li a,.pagination li span {
            height: 40px;
            width: 40px;
            font-size: 21px;
            color: #999;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .pagination li a, .pagination li span {
            padding: 0;
            text-align: center;
            border-color: #ddd;
            cursor: pointer
        }

        .pagination li a span {
            font-size: 21px;
        }

        .pagination .glyphicon-flag {
            display: block;
            color: #000;
            width: 40px;
            height: 20px;
            top: -20px;
            position: absolute;
            line-height: 20px
        }

        .pagination .glyphicon-flag {
            background-color: #ddd
        }

        .pagination .glyphicon-flag {
            left: -1px
        }

        .pagination .glyphicon-flag {
            right: -1px
        }

        .pagination .active:hover span, .pagination .active span {
            background-image: url("../assets/imgaes/pagination-bg.png");
            /*border-color: #ddd*/
            color: #ffffff;
        }

        .pagination .answered span {
            color: #505050
        }

        .pagination .answered span {
            background-color: #91cfbc
        }
        .operation{
            margin-left: 77px;
        }
    }
</style>